import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import Image from "../../components/image"
import SEO from "../../components/seo"
import styles from "./../work.module.sass"


const EdibleEnvelope = () => (
  <Layout>
    {/*---- Work Detail ----*/}
    <SEO title="Edible Envelope" />

    <article className={styles.workDetail}>
      <div style={{ padding:`56.25% 0 0 0`, position:`relative`}}>
        <iframe src="https://player.vimeo.com/video/217697778?title=0&byline=0&portrait=0" 
          style={{ position:`absolute`, top:`0`, left:`0`, width:`100%`, height:`100%`}} frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
      </div>
      <script src="https://player.vimeo.com/api/player.js"></script>

      <header className={styles.workDetail_header}>
        <h1 className={styles.workDetail_title}>Edible Envelope</h1>
        <h2 className={styles.workDetail_subTitle}>Designing a new communication interface through cooking</h2>
        <ul className={styles.workDetail_additionalInfo}>
          <li>2017 / Class Project (Solo)</li>
          <li>Role: Interaction Designer, Creative Technologist, Design Researcher </li>
          <li>Supervisors: Edd Bagenal and Liam Hinshelwood</li>
          <li>Main Tools: Web camera, Processing, OpenGL, Messenger App's API (LINE)</li>
          <li>Keywords: Communication Design, Conceptual Prototype</li>
        </ul>
      </header>

    {/* -------- Overview -------- */}
      <section className={styles.workDetail_section}>
        <h3 className={styles.workDetail_section_title}>Overview</h3>

        <p>
          Edible Envelope is a conceptual prototype system I implemented during a class project focusing on my own interest, creativity.
        </p>
        <p>
          This prototype system was designed for international students and their families. 
          In the system, the students can receive messages from their families but they need to cook envelope-like food for opening the message. (The food can be anything such as sandwich, omelette and riceball.)
          The food is captured by a webcam and the movie is sent to the family side with the student's reply message. 
        </p>
        <p>
          Technology has made modern lifestyle more convinient and efficient. However, this may decrease opportunities that people develop their own creative skills such as cooking.
        </p>
        <p>
          This work challenges to motivate the students to cook by adding the trigger to communicate to their families, 
          and augment their imagination about cooking. 
        </p>
        <div className={styles.workDetail_largeImage_wrapper}>
          <Image fileName={"edibleEnvelope/systemTotalFlow.png"} />
          <p className={styles.workDetail_images_caption}>Edible Envelope's total system flow</p>
        </div>

      </section>


      {/* -------- Design Process -------- */}
      <section className={styles.workDetail_section}>
        <h3 className={styles.workDetail_section_title}>Design Process</h3>


        <h4 className={styles.workDetail_section_subTitle}>Context</h4>
        <p>
          There are many evidences showing young people can not cook and be stressed by cooking. 
          In addition, two-thirds of students eat fast food every week in the UK [<a href="https://www.accommodationforstudents.com/research_reports/research.pdf" target="_blank">1</a>].
        </p>


        <h4 className={styles.workDetail_section_subTitle}>Research</h4>
        <p>
          In order to design a solution for this issue, I begun to interviewed about students’ eating habits such as what they cook and how to learn it. 
          Many students were taught how to cook by their mothers firstly, and then learnt more by themselves. 
          In addition, although some feel stressed by cooking, they want to like cooking more.
        </p>
        <p>
          At the same time, I also tried some experiments like sharing recipes with other students online and cooking the same food with different people offline/online. 
        </p>
        <div className={styles.workDetail_images_wrapper}>
          <Image fileName={"edibleEnvelope/process_sharedRecipe.png"} className={styles.workDetail_images_image} />
          <Image fileName={"edibleEnvelope/process_remoteCooking_02.png"} className={styles.workDetail_images_image} />
        </div>
        <p className={styles.workDetail_images_caption}>The students' recipes shared online and the experiment to cook the same meal with remote people via video chat</p>



        <h4 className={styles.workDetail_section_subTitle}>Analyse and Ideation</h4>
        <p>
          At the beginning, I focused on finding what kind of information about cooking students should share. 
          This is because I thought it could encourage PDCA cycle of cooking and improve the skills. 
          However, from the experiments described above I felt a kind of playfulness, 
          and I considered the process of sharing information might be able to motivate people, rather than the content itself. 
        </p>
        <p>
          From this perspective, I tried to figure out the form of the communication tool to boost motivation. 
          And I gradually focused on a motif of envelope because opening a letter can be considered as one of typical events to excite people. 
          I also thought it could increase users’ imagination and creativity if the tool I would design requires them to cook meal in specific form.
          Finally, I integrated these two ideas into one concept that people can communicate through cooking and opening envelope-like food.
        </p>


        <h4 className={styles.workDetail_section_subTitle}>System Design and Prototyping</h4>
        <p>
          After the ideation phase, I made an image recognition programme to detect envelope-like form, 
          and modified it to be an interactive prototype working with messenger App’s API. 
        </p>
        <div className={styles.workDetail_images_wrapper}>
          <div className={styles.workDetail_images_image} style={{ background:`#000`}}>
            <iframe src="https://player.vimeo.com/video/216248317?title=0&byline=0&portrait=0" 
              style={{ position:`absolute`, top:`0`, left:`0`, width:`100%`, height:`100%`}} frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
          </div>
          <Image fileName={"edibleEnvelope/process_systemDesign.png"} className={styles.workDetail_images_image} />
        </div>
        <p className={styles.workDetail_images_caption}>The interactive prototype with image recognition programme</p>



        <h4 className={styles.workDetail_section_subTitle}>Next Step</h4>
        <p>
          Although the interactive prototype looks not functional, it worked with some delight.
          Therefore, I would like to improve and test this conceptual system in the actual situation. 
          I believe it would show more functional and proper form. 
        </p>
        <p>
          Additionally, since this system records individual eating habit, the data has a lot of potential for health care, marketing and economy. 
        </p>
      </section>
    </article>
  </Layout>
)

export default EdibleEnvelope
